.image{
  width:auto !important;
}

.teamName{
  font-weight: bold;
}

.teamScore{
  font-weight: bold;
}

.teamRecord{
  font-weight: bold;
}

.section{
  padding-top: 2vh !important;
  padding-bottom: 2vh !important;
}

#loginName, #loginNameBtn{
  background-color: gray;
}

.App {
  text-align: center;
  max-width: 100vw;
  /*overflow-x: hidden; */
}

#TopBar_myLeague_lbl{
  margin:0 20px 0 0;
}

.container {
  max-width:95vw !important;
}

.loginBoxes{
  border-color: black;
  border-width: 2px;
  border-style: solid;
}

.competitionDate{
  font-size: small;
  font-weight: bold;
  font-style: italic;
}

.competitionStatus{
  font-size: medium;
  font-weight: bold;
  font-style: italic;
}

.inProgress{
  border: 1px solid black;
  color:white !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.winningComponent{
  border: 1px solid black;
  background-color: #E7FBE7 !important;
  color:black !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.notes.winningComponent{
  height:auto !important;
}

.losingComponent{
  border: 1px solid black;
  background-color: #FCE4E4 !important;
  color:black !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.notes.losingComponent{
  height:auto !important;
}

.unplayedComponent{
  border: 1px solid black;
  color:black !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.notes.unplayedComponent{
  height:auto !important;
}

.vs{
  padding:"10px 0 0 0 ";
}

.myPick{
  /*border: 4px solid #3377FF;*/
  border: 4px solid #00ff00;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.my.unplayedComponent:hover{
  background-color: #d2d7f7 !important;
}

.myPick.unplayedComponent{
  /*border: 4px solid #3377FF;*/
  border: 4px solid #00ff00;
  background-color: #a3aef5 !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.notes.myPick.unplayedComponent{
  height:auto !important;
}



.pickWon{
  background-color: lightgreen !important;
  border: 4px solid #13c248 !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.pickLost{
  border: 4px solid #e20707 !important;
  background-color: lightcoral !important;
  border-radius: 10px;
  min-height: 75px;
  margin: 2px 2px 2px 2px ;
}

.textPadding{
  padding:.25vh 0 0 0 !important ;
  display: inline !important;
  /*white-space: nowrap !important;*/
}

.leftScoreTextPadding{
  margin:-3vh 0 0 0 !important ;
  display: inline !important;
  /*white-space: nowrap !important;*/
}

.rightScoreTextPadding{
  margin:-3vh 0 0 0 !important ;
  display: inline !important;
  /*white-space: nowrap !important;*/
}

.winningTeam {
  font-weight: bold;
}

.losingTeam{
  font-style: italic;
}

.leagueReportUser{
  border: 1px solid #000000;
  border-radius: 10px;
  
  margin: 2px 2px 2px 2px ;
}

.leagueReportUserIconPadding{
  padding: initial !important;
}

.leageReport_Score{
  padding: 1vh 0 0 0;
}



.box{
  margin: 10px;
  padding: 20px;
  border: 2px solid salmon;
  text-align: center;
  color: salmon;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
